export default {
    name: "rólunk-tab",
    props: {
        input: Object
    },
    data() {
        return {
        };
    },
    created() {

    },
    methods: {

    }
};
