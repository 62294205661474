export default {
    name: "kezdőlap-tab",
    data() {
        return {
        };
    },
    created() {

    },
    methods: {

    }
};
